import {
    onRouteUpdate as onRouteUpdateInternal,
    shouldUpdateScroll as shouldUpdateScrollInternal,
    replaceHydrateFunction as replaceHydrateFunctionInternal,
    onPrefetchPathname as onPrefetchPathnameInternal,
    onPreRouteUpdate as onPreRouteUpdateInternal,
} from './src/gatsby/browser'

export const replaceHydrateFunction = replaceHydrateFunctionInternal
export const onRouteUpdate = onRouteUpdateInternal
export const shouldUpdateScroll = shouldUpdateScrollInternal
export const onPrefetchPathname = onPrefetchPathnameInternal
export const onPreRouteUpdate = onPreRouteUpdateInternal
